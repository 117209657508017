import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid } from 'react-loader-spinner';

import { sendEvent } from '../../helpers/analyticsHandler';
import { GetProductDetail, SubmitForm } from '../../services/dmpApi';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import FormAddToMarketplace from './FormAddToMarketplace';
import FormRequestAccess from './FormRequestAccess';

import './Form.scss';

const FormContainer = ({ id, isOpen, handleClose, header, typeForm, handleClickConfirmation, handleError, asset }) => {
    const requestTypeLabel = ''
    const productIdLabel = ''
    const productNameLabel = ''
    const productTypeLabel = ''
    const productOwnerLabel = ''
    const biLeadLabel = ''

    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState({});
    const formHeaders = {
        DIA: 'Summarize your data needs and how the data will be used',
        DAA: 'Who will need access?',
        DSA: 'What will the data be used for?'
    }
    const [submitRedirectToCollibra, setSubmitRedirectToCollibra] = useState(false);
    const [submitRedirectToSNOW, setSubmitRedirectToSNOW] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState('');  

    const requestTypeMapping = {
        'DIA': 'Data Ingestion Request',
        'DSA': 'Data Sharing Request',
        'DAA': 'Data Access Request',
        'DLA': 'Data Lake Access Request',
        'NIR': 'New Idea Request',
        'OR': 'Other Request',
        'NPI' : 'New Project Idea',
        'DER' : 'Product Enhancement Request',
        'DQI': 'Log Data Issue/Question',
        'GCR' : 'Gen AI Chatbot Request'
    }

    let clearChildState = null;
    const assignClearChildState = (clearState) => {
        clearChildState = clearState;
    }

    let validateFormSubmission = null;
    const assignValidateFormSubmission = (validate) => {
        validateFormSubmission = validate;
    }

    const handleCloseModal = () => {
        handleClose();
        clearChildState();
        setFormFields({});
    }

    const handleSelectedProduct = (product) => {
        setSelectedAsset(product);
    }

    useEffect(() => {
        if(typeForm==='DAA'){
            setSelectedAsset(asset);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[asset]);

    const handleFormFieldChange = (childState) => {
        setSubmitRedirectToCollibra(false);
        setSubmitRedirectToSNOW(false);
        setFormFields({...formFields, ...childState});
        if(childState.dropdownRequestType?.value === 'Product Enhancement Request' || childState.dropdownRequestType?.value ==='Data Lake Ingestion Request' || childState.dropdownRequestType?.value === 'Log Data Issue/Question'){
            setSubmitRedirectToCollibra(true);
        }
        if(childState.dropdownRequestType?.value === 'Data Lake Access Request'){
            setSubmitRedirectToSNOW(true);
        }
    }

    const handleSubmit = async () => {
        const valid = validateFormSubmission();

        if (valid) {
            setIsLoading(true);
            let assetId = selectedAsset?.id ? selectedAsset?.id : 'None';
            let assetName = selectedAsset?.name ? selectedAsset?.name : 'None';
            let assetType = selectedAsset?.type ? selectedAsset?.type : 'None';
            let assetProductOwner = selectedAsset?.primaryOwners?.find(owner => owner.roleName === 'Product Owner');
            if (assetType==='Gen AI Use Case'){assetProductOwner = selectedAsset?.secondaryOwners?.find(owner => owner.roleName === 'Product Owner');}
            let assetProductOwnerEmail = assetProductOwner ? assetProductOwner.email : 'None';
            let assetBILead = selectedAsset?.secondaryOwners?.find(owner => owner.roleName === 'BI Lead');
            let assetBILeadEmail = assetBILead ? assetBILead.email : 'None';
            let assetBusinessOwnerEmail = selectedAsset?.secondaryOwners?.filter(owner => owner.roleName === 'Business Sponsor').map(owner => owner.email);
            let assetADGroup = '';

            //fetching cloud ad group for gen ai assets
            if(assetType === 'Gen AI Use Case'){
                await GetProductDetail(assetId)
                    .then(response => {
                    assetADGroup = response.data?.relationships?.sources?.filter(item => item.role==="ai access is granted by").map(item => item.name);
                    
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }

            //type form might change based on drop down selection for a Data Ingestion Request (DIA)
            if (typeForm === "DIA" && (formFields.dropdownRequestType.value !=='Data Lake Ingestion Request')) {
                if(formFields.dropdownRequestType.value === 'New Project Idea') typeForm='NPI'
                if(formFields.dropdownRequestType.value === 'Product Enhancement Request') typeForm='DER'
                if(formFields.dropdownRequestType.value === 'Log Data Issue/Question') typeForm='DQI'
                if(formFields.dropdownRequestType.value === 'Data Lake Access Request') typeForm='DLA'
                if(formFields.dropdownRequestType.value === 'PowerBI Access Request' || formFields.dropdownRequestType.value === 'Gen AI Chatbot Request'){
                    formFields.dropdownRequestType.value === 'PowerBI Access Request' ? typeForm='DAA' : typeForm='GCR'
                     formFields.dropdownRequestType.value === 'PowerBI Access Request' ? assetType = 'Dashboard' : assetType = 'Gen AI Use Case'
                     assetName = formFields.dropdownProductName.value
                    }
            }
            

            if(typeForm==='DER') assetType='Dashboard'
            if(typeForm==='DIA' || typeForm==='DQI') assetType='Ingestion'
            
            const payload = {
                requestType: {prompt: requestTypeLabel, value: requestTypeMapping[typeForm]},
                productId: { prompt: productIdLabel, value: assetId },
                productName: { prompt: productNameLabel, value: assetName },
                productType: { prompt: productTypeLabel, value: assetType },
                productOwner: { prompt: productOwnerLabel, value: assetProductOwnerEmail},
                biLead: { prompt: biLeadLabel, value: assetBILeadEmail},
                requestDate: {prompt: formFields.requestDate.prompt, value: formFields.requestDate.value},
                summary: {prompt: formFields.summary.prompt, value: formFields.summary.value},
                requestedFor: {prompt: formFields.requestedFor.prompt, value: formFields.requestedFor.value},
                businessOwner: {prompt: '', value: assetBusinessOwnerEmail ? assetBusinessOwnerEmail.toString() : 'None'},
                cloudADGroup: {prompt: '', value: assetADGroup ? assetADGroup.toString() : 'None'}
            }
            var check = false;
            if(typeForm==='DIA' || typeForm==='DQI' || typeForm==='DLA' || typeForm==='DER'){
                const newWindow = window.open(typeForm==='DLA' ? 'https://nrg.service-now.com/sp?id=sc_cat_item&sys_id=e415062edb9140d0e59f5bd2ca9619e6' : `${process.env.REACT_APP_NRG_COLLIBRA_URL}/dashboard?d=00000000-0000-0000-0000-000000110002`, '_blank');

                if(newWindow){ 
                    check=true;  
                }
                else{
                    setIsLoading(false)
                    handleError((typeForm==='DIA' || typeForm==='DQI' || typeForm==='DER') ? 'collibra' : (typeForm==='DLA') ? 'SNOW' : '');
                }
            }
            
            if(check || (typeForm!=='DIA' && typeForm!=='DQI' && typeForm!=='DLA' && typeForm!=='DER')){
                SubmitForm(payload)
                .then(res => {
                    setIsLoading(false);
                    handleClickConfirmation((typeForm==='DIA' || typeForm==='DQI' || typeForm==='DER') ? 'collibra' : (typeForm==='DLA') ? 'SNOW' : '');
                    sendEvent('analyticsClickAction', 'modalSubmit', typeForm, null, asset ? asset : null);
                    
                }).catch(err => {
                    setIsLoading(false);
                    console.error(err);
                    handleError((typeForm==='DIA' || typeForm==='DQI' || typeForm==='DER') ? 'collibra' : (typeForm==='DLA') ? 'SNOW' : '');
                });
            
            setFormFields({});
            }
            
        }
    }

    return (
        <>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleCloseModal} size="md">
                    {header && <div className='modal-header'>{header}</div>}
                    <div className='modal-form-content'>
                        <div className='form'>
                            <div className='form--header'>
                                {formHeaders[typeForm]}
                            </div>
                            <form>        
                                {(typeForm === 'DIA' && <FormAddToMarketplace 
                                    id={id} 
                                    onFieldChange={handleFormFieldChange}
                                    passClearStateFunction={assignClearChildState} 
                                    passValidateFormFunction={assignValidateFormSubmission}
                                    handleSelectedProduct={handleSelectedProduct} />) ||
                                (typeForm === 'DAA' && <FormRequestAccess 
                                    id={id} 
                                    onFieldChange={handleFormFieldChange} 
                                    passClearStateFunction={assignClearChildState} 
                                    passValidateFormFunction={assignValidateFormSubmission}
                                    assetName={asset?.name} />) }
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            id={`${id}-submit-btn`}
                            className='btn--rounded btn--blue-light btn--submit'
                            onClick={() => handleSubmit()}
                            disabled={isLoading}>
                            {submitRedirectToCollibra ? "Submit in Collibra" : submitRedirectToSNOW ? "Submit in Service Now" : "Submit"}
                        </Button>
                        {isLoading && <span><Grid color="#999999" height={20} width={20} ariaLabel='loading' wrapperClass="modal-spinner" /></span>}
                    </div>
                </Modal>
            }
        </>
    )
}

FormContainer.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    header: PropTypes.string,
    typeForm: PropTypes.string,
    handleClickConfirmation: PropTypes.func,
    handleError: PropTypes.func.isRequired,
    asset: PropTypes.any
}

export default FormContainer;