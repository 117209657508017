import axios from 'axios';

import userState from '../components/Login/CurrentUser';

const LOGIN_RETRY_COUNT = 5;

export const GetProductDetail = async function (id, retryCount = 0) {

    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    try {
        return await axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/details/${id}`, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await GetProductDetail(id, r) });
    }
}

export const ProductSearch = async function (term, offset = 0, limit = 10000, retryCount = 0) {
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }

    let body = {
        "offset": offset,
        "limit": limit
    }
    if (term) body.term = term;

    const searchEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/search${process.env.REACT_APP_INCLUDE_TEST_DATA === true ? '?testdata=1' : ''}`;
    try {
        return await axios.post(searchEndpoint, body, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await ProductSearch(term, offset, limit, r) });
    }
}

// This needs to be tracked across all threads because the retry resets the auth token which makes a new call to
// GetProfile in a new thread
class ProfileCounter {
    static counter;
    static incrementCounter() {
        if (ProfileCounter.counter) ProfileCounter.counter++;
        else ProfileCounter.counter = 1;
    }
    static getCounter() {
        return ProfileCounter.counter
    }
    static resetCounter() {
        ProfileCounter.counter = 0
    }
}

export const GetProfile = async function () {
    // requests after the retry count is exceeded will still be executed but will not retry
    ProfileCounter.incrementCounter();
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    try {
        let result = await axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/user`, config);

        // reset the counter on success so that future requests can be retried
        ProfileCounter.resetCounter();

        return result;
    } catch (error) {
        return await handleError(error, ProfileCounter.getCounter(), GetProfile);
    }
}

export const GetPermission = async function (retryCount = 0) {
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    try {
        return await axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/permissions`, config);
    } catch (error) {
        return await handleError(error, retryCount, GetPermission);
    }
}

export const GetDownloadPresignedUrl = async function(runId, retryCount = 0){
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    let body = {
        "run_id": runId
    }
    try {
        return await axios
            .post(`${process.env.REACT_APP_API_ENDPOINT}/modelfiledownload`, body, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await GetDownloadPresignedUrl(runId)});
    }
}

export const GetPreSignedUrl = async function (assetId, assetName, fileExtension, parameterName, retryCount = 0) {
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    let body = {
        "asset_id": assetId,
        "model_name": assetName,
        "parameter_name" : parameterName,
        "file_extension" : fileExtension,
    }
    try {
        return await axios
            .post(`${process.env.REACT_APP_API_ENDPOINT}/modelfile`, body, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await GetPreSignedUrl(assetId, assetName, fileExtension, parameterName)});
    }
}

export const UploadDataScienceS3File = async function (presignedUrlDetails,selectedFile,onUploadProgress, retryCount = 0){
    var data = new FormData();
    data.append('key',presignedUrlDetails.fields.key)
    data.append('AWSAccessKeyId',presignedUrlDetails.fields.AWSAccessKeyId)
    data.append('policy',presignedUrlDetails.fields.policy)
    data.append('signature',presignedUrlDetails.fields.signature)
    data.append('x-amz-security-token',presignedUrlDetails.fields['x-amz-security-token'])
    data.append('file',selectedFile)
    let body = data;
    
    try {
        return await axios
            .post(presignedUrlDetails.url,body,{onUploadProgress}).then(response => {if(response.ok)
                return response
            });
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await UploadDataScienceS3File(presignedUrlDetails,selectedFile,onUploadProgress)});
    }
    
}

export const GetLastDSModelRun = async function (asset_id, retryCount = 0) {
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    let body = {
        "asset_id": asset_id,
    }
    try {
        return await axios
            .post(`${process.env.REACT_APP_API_ENDPOINT}/lastmodelrun`, body, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await GetLastDSModelRun(asset_id)});
    }
}

export const FavoriteProduct = async function (id, retryCount = 0) {

    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }

    try {
        return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/favorite/${id}`, "", config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await FavoriteProduct(id, r) });
    }
}

export const TrackAssetUsage = async function (id, retryCount = 0) {

    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }

    let body = {
        "assetId": id,
        "accessType": "LAUNCH PRODUCT"
    }

    try {
        return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/assetHit`, body, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await TrackAssetUsage(id, r) });
    }
}

export const GetGlossary = async function (limit, offset, retryCount = 0) {

    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }

    try {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/glossary?limit=${limit}&offset=${offset}`, config);
    } catch (error) {
        return await handleError(error, retryCount, GetGlossary);
    }
}

export const ExecuteDSModel = async function (model_id, asset_id, formFields, retryCount = 0){
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    let body ={
          "model_id": model_id,
          "asset_id": asset_id,
          "model_named_params": {...formFields}
      } 
      
    try {
        return await axios
            .post(`${process.env.REACT_APP_API_ENDPOINT}/model`, body, config);
    } catch (error) {
        //return error;
        return await handleError(error, retryCount, async (r) => { return await ExecuteDSModel(model_id, asset_id, formFields)});
    }
}

export const GetPersonas = async function (retryCount = 0) {
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }

    try {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/personas`, config);
    } catch (error) {
        return await handleError(error, retryCount, GetGlossary);
    }
}

export const addPersonaUser = async function(personaName, formData, retryCount = 0){
    let config = {
        method: 'POST',
        headers: { Authorization: await userState.getAccessToken(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    try {
        return await fetch(`${process.env.REACT_APP_API_ENDPOINT}/personas/${personaName}/users`, config)
            .then(response =>{
                if(response.ok) return response;
                throw response;
            });
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await addPersonaUser(personaName, formData, r) });
    }
}

export const SubmitForm = async function (formData, retryCount = 0) {
    let config = {
        method: 'POST',
        headers: { Authorization: await userState.getAccessToken(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    try {
        return await fetch(`${process.env.REACT_APP_API_ENDPOINT}/request`, config)
            .then(response =>{
                if(response.ok) return response;
                throw response;
            });
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await SubmitForm(formData, r) });
    }
}

export const SubmitPermission = async function (formData, retryCount = 0) {
    let config = {
        method: 'POST',
        headers: { Authorization: await userState.getAccessToken(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    try {
        return await fetch(`${process.env.REACT_APP_API_ENDPOINT}/permission`, config)
        .then(response =>{
            if(response.ok) return response;
            throw response;
        });
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await SubmitPermission(formData, r) });
    }
}

export const AddPersonaRecommendations = async function (formData, retryCount = 0) {
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }

    try {
        return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/recommendations`, formData, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await AddPersonaRecommendations(formData, r) });
    }
}

export const AddPersonaFeatures = async function (formData, retryCount = 0) {
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }

    try {
        return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/update`, formData, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await AddPersonaFeatures(formData, r) });
    }
}

export const RemovePersonaRecommendation = async function (id, retryCount = 0) {
    let config = {
        headers: { Authorization: await userState.getAccessToken()},
    };
    try {
        return await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/recommendations/${id}`, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await RemovePersonaRecommendation(id, r) });
    }
}

export const RemovePersonaFeature = async function (id, retryCount = 0) {
    let config = {
        headers: { Authorization: await userState.getAccessToken()},
    };
    try {
        return await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/features/${id}`, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await RemovePersonaFeature(id, r) });
    }
}

export const DeletePermission = async function (id, retryCount = 0) {
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    try {
        return await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/permissions/${id}`, config);
    } catch (error) {
        return await handleError(error, retryCount, async (r) => { return await DeletePermission(id, r); });
    }
}

export const GetAssetPermissions = async function (assetType) {
    // requests after the retry count is exceeded will still be executed but will not retry
    ProfileCounter.incrementCounter();
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    try {
        let result = await axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/permissions/assets?type=${assetType}`, config);

        // reset the counter on success so that future requests can be retried
        ProfileCounter.resetCounter();

        return result;
    } catch (error) {
        return await handleError(error, ProfileCounter.getCounter(), async (r) => { return await GetAssetPermissions(assetType); });
    }
}

export const GetAllAssetPermissions = async function () {
    // requests after the retry count is exceeded will still be executed but will not retry
    ProfileCounter.incrementCounter();
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    try {
        let result = await axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/permissions/assets`, config);

        // reset the counter on success so that future requests can be retried
        ProfileCounter.resetCounter();

        return result;
    } catch (error) {
        return await handleError(error, ProfileCounter.getCounter(), async (r) => { return await GetAllAssetPermissions(); });
    }
}

export const GetUpdates = async function () {
    // requests after the retry count is exceeded will still be executed but will not retry
    ProfileCounter.incrementCounter();
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    try {
        let result = await axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/updates`, config);

        // reset the counter on success so that future requests can be retried
        ProfileCounter.resetCounter();

        return result;
    } catch (error) {
        return await handleError(error, ProfileCounter.getCounter(), GetUpdates);
    }
}

export const GetMedia = async function () {
    // requests after the retry count is exceeded will still be executed but will not retry
    ProfileCounter.incrementCounter();
    let config = {
        headers: {
            Authorization: await userState.getAccessToken(),
        }
    }
    try {
        let result = await axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/media`, config);

        // reset the counter on success so that future requests can be retried
        ProfileCounter.resetCounter();

        return result;
    } catch (error) {
        return await handleError(error, ProfileCounter.getCounter(), GetMedia);
    }
}

const handleError = async function (error, retryCount, retryFunc) {
    if (retryCount < LOGIN_RETRY_COUNT && error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.log('invalid user token, retrying');
        userState.expireToken();

        return await retryFunc(retryCount + 1);
    } else {
        throw error;
    }
}